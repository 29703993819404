import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Kom i gang",
  "order": 0,
  "template": "default",
  "introtext": "Designsystemet inneholder ressursene du trenger for å lage løsninger som er i stil med SpareBank 1s designprinsipper, stil og tone og best practices",
  "illustration": "moon.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`For designere`}</h2>
    <ul>
      <li parentName="ul">{`Bli kjent med `}<a parentName="li" {...{
          "href": "/profil/stil-og-tone/"
        }}>{`profilen til SpareBank 1`}</a></li>
      <li parentName="ul">{`Gå igjennom komponentseksjonen for å gjøre deg kjent med de eksisterende komponentene du kan implementere i designet ditt`}</li>
      <li parentName="ul">{`Sjekk ut designsystem-bibliotekene i Figma. Der finner du komponentene, gridstilene, tekststilene, fargestilene, ikonene og illustrasjonene du trenger når du skal skisse ut løsninger. Du finner bibliotekene under assets. De er alltid tilgjengelige så lenge du er i SpareBank 1-organisasjonen`}</li>
    </ul>
    <h2>{`For utviklere`}</h2>
    <p>{`FFE er en samling av gjenbrukbare komponenter basert på Less og React for å bygge nettsider og brukergrensesnitt. Komponentbiblioteket sikrer konsistent stil og oppførsel i prototyper og produksjonsarbeid.`}</p>
    <p>{`Komponentene er publisert i forskjellige pakker på npm under `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/org/sb1"
      }}>{`@sb1-organisasjonen`}</a>{`. Kildekoden til pakkene ligger på `}<a parentName="p" {...{
        "href": "https://github.com/SpareBank1/designsystem/tree/develop/packages"
      }}>{`Github`}</a>{` sammen med READMEs med mer detaljerte instruksjoner for hvordan pakkene kan brukes i prosjektet ditt.`}</p>
    <p>{`Less-pakker brukes som oftest ved å ha en egen Less-fil i ditt prosjekt med et `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@import`}</code>{`-statement for hver pakke, som her for `}<a parentName="p" {...{
        "href": "https://github.com/SpareBank1/designsystem/tree/develop/packages/ffe-buttons#usage"
      }}>{`@sb1/ffe-buttons`}</a>{`. React-pakker brukes på lik linje med enhver JavaScript-pakke. Se siden til den komponenten du er interessert i, for å se et eksempel på en import-statement.`}</p>
    <h3>{`Eksempel på importkode:`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}>{`// src/[din-app]-main.less
`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@import`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'@sb1/ffe-buttons/less/buttons.less'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@import`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'@sb1/ffe-cards/less/cards.less'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@import`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'@sb1/ffe-core/less/ffe'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span></code></pre></div>
    <p>{`Alle vil trenge `}<a parentName="p" {...{
        "href": "https://github.com/SpareBank1/designsystem/tree/develop/packages/ffe-core"
      }}>{`@sb1/ffe-core`}</a>{` og `}<a parentName="p" {...{
        "href": "https://github.com/SpareBank1/designsystem/tree/develop/packages/ffe-webfonts"
      }}>{`@sb1/ffe-webfonts`}</a>{`. Utenom det har alle forskjellige behov, så ta en titt på komponentene for å finne det du trenger.`}</p>
    <h2>{`Designsystem-teamet`}</h2>
    <p>{`Designsystemet blir tatt vare på av designsystem-teamet. Du kan finne oss på Slack-kanalen #ext-designsystem.`}</p>
    <h2>{`Bidra`}</h2>
    <p>{`Selv om vi er et team oppfordrer vi alle, både rutinerte og ferske, til å opprette issues, sende inn pull requests og kommentere på andres bidrag. Ta en titt under «Bidra» i sidemenyen for mer info om hvordan du går frem.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      